import { Component, Input, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, of } from 'rxjs';
import { Column } from '@app/shared/components/responsive-table/column.type';
import { GenericDataSource } from '@app/shared/components/responsive-table/generic.datasource';

@Component({
  selector: 'app-responsive-table',
  templateUrl: './responsive-table.component.html',
  styleUrls: ['./responsive-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ overflow: 'hidden', height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ overflow: 'hidden', height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ResponsiveTableComponent {
  public CTRL_COLUMN_WIDTH = 70;
  public DATA_COLUMN_WIDTH = 100;

  visibleColumns: Column[] = [];
  hiddenColumns: Column[] = [];
  expandedElement: boolean[] = [];

  @Output() clickedRows = new EventEmitter<any>();
  @Output() clickedCells = new EventEmitter<any>();

  @Input() source!: DataSource<any>;
  @Input() selection!: SelectionModel<any>;
  @Input() dataLength$!: Observable<number>;
  @Input() columns: Column[] = [];
  @Input() paginatorShown = true;

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  get visibleColumnsIds() {
    const visibleColumnsIds = this.visibleColumns.map(column => column.id);

    if (this.hiddenColumns.length) {
      visibleColumnsIds.unshift('trigger');
    }

    return this.selection ? ['select', ...visibleColumnsIds] : visibleColumnsIds;
  }

  get hiddenColumnsIds() {
    return this.hiddenColumns.map(column => column.id);
  }

  get loading() {
    return this.source instanceof GenericDataSource
      ? this.source.loading$
      : of (false)
    ;
  }

  constructor(
    private zone: NgZone,
    private paginatorIntl: MatPaginatorIntl,
  ) {
    paginatorIntl.itemsPerPageLabel = $localize`:@@paginator.items_per_page:Items per page`;
    paginatorIntl.nextPageLabel = $localize`:@@paginator.next_page:Next page`;
    paginatorIntl.previousPageLabel = $localize`:@@paginator.previous_page:Previous page`;
    paginatorIntl.firstPageLabel = $localize`:@@paginator.first_page:First page`;
    paginatorIntl.lastPageLabel = $localize`:@@paginator.last_page:Last page`;

    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const begin = page * pageSize;
      const end = Math.min(begin + pageSize, length);

      return `${length ? `${begin + 1} - ${end}` : begin} ${$localize`:@@paginator.of:of`} ${length}`;
    }
  }

  onResize(ev: ResizeObserverEntry) {
    this.toggleColumns(ev.contentRect.width);
  }

  toggleColumns(tableWidth: number) {
    this.zone.runOutsideAngular(() => {
      const columns = this.columns.slice()
        .map(
          (column, index) => (
            { ...column, toggleWidth: column.width ?? this.DATA_COLUMN_WIDTH, order: index }
          )
        )
        .sort(
          (a, b) => b.hideOrder - a.hideOrder
        )
      ;

      [() => this.selection, () => columns.reduce((a, b: any) => a + b.toggleWidth, 0) > tableWidth]
        .forEach(v => tableWidth -= v() ? this.CTRL_COLUMN_WIDTH : 0)
      ;

      for (const column of columns) {
        if (column.hideOrder && tableWidth < column.toggleWidth) {
          column.visible = false;
        } else {
          column.visible = true;
          tableWidth -= column.toggleWidth;
        }
      }

      this.columns = columns.sort(
        (a, b) => a.order - b.order
      );
      this.visibleColumns = this.columns.filter(column => column.visible);
      this.hiddenColumns = this.columns.filter(column => !column.visible);
    });
  }

  resetSelection() {
    if (!this.selection.isEmpty()) {
      this.selection.clear();
    }
  }

  clickRow(row: any) {
    this.clickedRows.emit(row);
  }

  clickCell(cell: any, row: any) {
    this.clickedCells.emit({cell, row});
  }
}
