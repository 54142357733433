import { FixedLengthArray } from 'utils';

type UNumber = number | undefined;
type Pair = FixedLengthArray<any, 2>;

export type TSearchLogic = 'and' | 'or' | undefined;
export type TSearchOperator = 'like' | 'is' | 'greater' | 'less' | 'in';
export type TSortDirection = 'asc' | 'desc';

export interface IApiParamSearch {
    field?: string;
    operator?: TSearchOperator;
    value?: any;
    not?: boolean;
}

export interface IApiParamSort {
    field?: string;
    direction?: TSortDirection;
}

export interface IApiParams {
    limit: UNumber;
    offset: UNumber;
    searchLogic: TSearchLogic;
    search: IApiParamSearch[];
    sort: IApiParamSort[];
}

export class ApiParams {
    private state: IApiParams = {
        limit: undefined,
        offset: undefined,
        searchLogic: undefined,
        search: [],
        sort: []
    };

    constructor(params?: IApiParams) {
        if (params !== undefined) {
            this.state = params;
        }
    }

    set limit(limit: UNumber) {
        this.state.limit = limit;
    }

    get limit(): UNumber {
        return this.state.limit;
    }

    set offset(offset: UNumber) {
        this.state.offset = offset;
    }

    get offset(): UNumber {
        return this.state.offset;
    }

    set searchLogic(searchLogic: TSearchLogic) {
        this.state.searchLogic = searchLogic;
    }

    get searchLogic(): TSearchLogic {
        return this.state.searchLogic;
    }

    get search(): IApiParamSearch[] {
        return this.state.search;
    }

    get sort(): IApiParamSort[] {
        return this.state.sort;
    }

    get all(): IApiParams {
        return this.state;
    }

    addSearch(field: string, operator: TSearchOperator, value: any, not: boolean = false) {
        const search: IApiParamSearch = {
            field,
            operator,
            value,
            not
        };

        this.state.search.push(search);

        return this;
    }

    addSort(field: string, direction: TSortDirection) {
        const sort: IApiParamSort = {
            field,
            direction
        };

        this.state.sort.push(sort);

        return this;
    }

    public toString = (): string => {
        const getPairs = (obj: object, keys: string[] = []) =>
            Object.entries(obj).reduce((pairs: Pair[], [k, v]) => {
                if (typeof v === 'object') {
                    pairs.push(...getPairs(v, [...keys, k]));
                } else {
                    pairs.push([[...keys, k], v]);
                }
                return pairs;
            }, []);

        const value = getPairs(this.state)
            .filter((pair: Pair) => pair[1] !== undefined)
            .map(([[key0, ...keysRest], v]) =>
                `${key0}${keysRest.map((a: string) => `[${a}]`).join('')}=${v}`)
            .join('&');

        return value ? `?${value}` : value;
    }
}

export interface IApiGet {
    count?: number;
    result?: any;
    error?: any;
    message?: string;
    code?: string;
    totalCount?: any;
}

export class ApiGet {
    private state: IApiGet = {
        count: 0,
        result: false,
        error: false,
        message: '',
        code: '',
        totalCount: null
    };

    constructor(data?: IApiGet) {
        if (data !== undefined) {
            this.state = data;
        }
    }

    set result(data: any[]) {
        this.state.result = data;
    }

    get result(): any[] {
        return Array.isArray(this.state.result) ? this.state.result : [];
    }

    get error(): any {
        return this.state.error;
    }

    get totalCount(): number {
        return isNaN(this.state.totalCount) ? 0 : Number(this.state.totalCount);
    }
}

export interface IApiGetRecord {
  record?: any;
}

export class ApiGetRecord {
  private state: IApiGetRecord = {
    record: null
  };

  constructor(data?: IApiGetRecord) {
    if (data !== undefined) {
      this.state = data;
    }
  }

  set record(data: any) {
    this.state.record = data;
  }

  get record(): any {
    return this.state.record;
  }
}
