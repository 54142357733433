<ng-template #formatCellContent let-column="column" let-row="row" let-value="value">
  <ng-container [ngSwitch]="column.type">
    <ng-container *ngSwitchCase="'date'">
      {{ value | date }}
    </ng-container>
    <ng-container *ngSwitchCase="'money'">
      {{ value | currency }}
    </ng-container>
    <ng-container *ngSwitchCase="'number'">
      {{ value | number }}
    </ng-container>
    <ng-container *ngSwitchCase="'link'">
      <a [routerLink]="column.format(row)">{{ value }}</a>
    </ng-container>
    <ng-container *ngSwitchCase="'icon'"
      [ngTemplateOutlet]="formatCellIcon" [ngTemplateOutletContext]="{format:column.format, row:row, value:value}">
    </ng-container>
    <ng-container *ngSwitchCase="'button'"
      [ngTemplateOutlet]="formatCellButton" [ngTemplateOutletContext]="{format:column.format, row:row, value:value}">
    </ng-container>
    <ng-container *ngSwitchDefault>{{value}}</ng-container>
  </ng-container>
</ng-template>

<ng-template #formatCellIcon let-format="format" let-row="row" let-value="value">
  <ng-container [ngSwitch]="format.type">
    <ng-container *ngSwitchCase="'reclaims'">
      <mat-icon *ngIf="value > 0" matTooltip="{{ value }}">notifications_active</mat-icon>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-icon *ngIf="value !== null" matTooltip="{{ value }}">label</mat-icon>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #formatCellButton let-format="format" let-row="row" let-value="value">
  <ng-container [ngSwitch]="format.type">
    <ng-container *ngSwitchCase="'countfromentries'">
      <mat-icon *ngIf="value > 0" class="clickable" (click)="clickCell(format.type, row)" matTooltip="{{ value }}">content_copy</mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="'matchingcodestateview'">
      <ng-container [ngSwitch]="value">
        <ng-container *ngSwitchCase="3">
          <mat-icon>lock</mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <mat-icon>lock_open</mat-icon>
        </ng-container>
        <ng-container *ngSwitchDefault/>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>{{ value }}"</ng-container>
  </ng-container>
</ng-template>

<div class="spinner-container">
  <div *ngIf="loading | async"
    fxLayoutAlign="center center">
    <mat-spinner diameter="50"/>
  </div>
  <mat-table resizeObserver (resize)="onResize($event)" [dataSource]="source" matSort multiTemplateDataRows>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef [fxFlex]="CTRL_COLUMN_WIDTH + 'px'">
        <button mat-icon-button *ngIf="selection.isMultipleSelection() && !selection.isEmpty()"
          i18n-matTooltip="@@reset_selection" matTooltip="reset selection"
          [disabled]="selection.isEmpty()"
          (click)="resetSelection()">
          <mat-icon
            [matBadge]="selection.selected.length" matBadgePosition="before" matBadgeSize="small" matBadgeColor="accent">
            deselect
          </mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [fxFlex]="CTRL_COLUMN_WIDTH + 'px'">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event && selection ? this.selection.toggle(row) : null"
          [checked]="selection && selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="trigger">
      <mat-header-cell *matHeaderCellDef [fxFlex]="CTRL_COLUMN_WIDTH + 'px'"/>
      <mat-cell *matCellDef="let row; let i = dataIndex;" [fxFlex]="CTRL_COLUMN_WIDTH + 'px'" (click)="$event.stopPropagation()">
        <span
          (click)="expandedElement[i] = !expandedElement[i]"
          [ngClass]="[expandedElement[i] ?  'active' : '']" class="toggle-button">
        </span>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="column.id" *ngFor="let column of visibleColumns">
      <mat-header-cell mat-sort-header *matHeaderCellDef
        [fxFlex]="column.width ? column.width.toString()  + 'px' : null"
        [fxLayoutAlign]="['number', 'money'].includes(column.type) ? 'end center' : 'start center'">
        <span class="capitalize">{{ column.label }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row"
        [fxFlex]="column.width ? column.width.toString()  + 'px' : null"
        [fxLayoutAlign]="['number', 'money'].includes(column.type) ? 'end center' : 'start center'">
        <ng-container
          [ngTemplateOutlet]="formatCellContent"
          [ngTemplateOutletContext]="{column:column, row:row, value:row[column.id]}">
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="hidden">
      <mat-cell *matCellDef="let row; let i = dataIndex;"
        class="hidden-cell"
        fxLayout="column" fxLayoutAlign="center start">
        <p fxLayout="start center" *ngFor="let hiddenColumn of hiddenColumns">
          <strong class="capitalize">{{ hiddenColumn.label }}:</strong>
          <ng-container
            [ngTemplateOutlet]="formatCellContent" [ngTemplateOutletContext]="{column:hiddenColumn, row:row, value:row[hiddenColumn.id]}">
          </ng-container>
        </p>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="visibleColumnsIds; sticky: true"/>
    <mat-row *matRowDef="let row; let i = dataIndex; columns: visibleColumnsIds"
      class="visible-row-{{i}}"
      (click)="clickRow(row)">
    </mat-row>
    <mat-row *matRowDef="let row; let i = dataIndex; columns: ['hidden'];"
      [@detailExpand]="expandedElement.length && expandedElement[i] ? 'expanded' : 'collapsed'"
       class="hidden-row-{{i}} column-length-{{hiddenColumns.length}}"
      [ngClass]="expandedElement[i] ? 'expanded-row' : 'collapsed-row'">
    </mat-row>
  </mat-table>
  <ng-container *ngIf="(dataLength$ | async) === 0">
    <p class="empty-table" fxLayoutAlign="center center" i18n="@@no_data">no data</p>
  </ng-container>
  <mat-paginator fxShow="{{ paginatorShown }}" hidePageSize showFirstLastButtons/>
</div>
