import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiV1Service } from '@app/shared/services/api-v1.service';
import { ApiParams, ApiGet } from '@app/shared/models/api.model';
import { Account, IAccount } from '@app/account/models/account.model';
import { HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
export class AccountService implements Resolve<Account> {

  private selection = new Map<number, Observable<Account>>();

  constructor(
    private apiService: ApiV1Service
  ) { }

  getAdvanced(params: HttpParams = new HttpParams(), apiParams: ApiParams = new ApiParams()): Observable<ApiGet> {
    return this.apiService.get('/accounts', params, apiParams)
      .pipe(map(response => {
        response.result = response.result.map((account: IAccount) => new Account(account));

        return response;
      }));
  }

  get(id: number, params: HttpParams = new HttpParams()){
    return this.apiService.getRecord(`/accounts/${id}`, params)
      .pipe(
        map(response => {
          return new Account(response.record);
        })
      )
    ;
  }

  select(selected: Account) {
    if (!this.selection.has(selected.id)) {
      this.selection.set(selected.id, of(selected));
    }
  }

  deselect(deselected: Account) {
    this.selection.delete(deselected.id);
  }

  getSelected(id: number) {
    if (!this.selection.has(id)) {
      this.selection.set(
        id,
        this.get(id).pipe(
          shareReplay(1)
        )
      );
    }
    return this.selection.get(id)!;
  }

  getSelection() {
    return this.selection;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Account> {
    return this.getSelected(
      route.params.id
    );
  }
}
