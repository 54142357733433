import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@app/../environments/environment';
import { ConfigService } from '@app/shared/services/config.service';

if (environment.production) {
  enableProdMode();
}

fetch('/assets/config.json').then(async config => {
  platformBrowserDynamic([
    { provide: ConfigService, useValue: new ConfigService(await config.json()) }
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
