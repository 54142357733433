import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@app/auth/auth.service';
import { VarsService } from '@app/shared/services/vars.service';
import { NavigationPage, NavigationService } from '@app/layout/services/navigation.service';
import { Observable, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-layout-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit, OnDestroy {
  items!: NavigationPage[];

  private subscription!: Subscription;

  isAuthenticated$: Observable<boolean>;
  isHandset$: Observable<boolean>;

  authenticatedUserName!: string;
  drawerExpanded = false;

  @ViewChild('drawer') drawer!: MatSidenav;

  constructor(
    private authService: AuthService,
    private varsService: VarsService,
    private breakpointObserver: BreakpointObserver,
    private navigationService: NavigationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      "finbox",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/layout/finbox.svg")
    );

    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isHandset$ = this.breakpointObserver.observe('(max-width: 960px)').pipe(
      map(result => result.matches)
    );
  }

  ngOnInit() {
    this.isAuthenticated$
      .pipe(filter(isAuthenticated => isAuthenticated))
      .subscribe(() => {
        this.authenticatedUserName = this.varsService.user().name;
      });

    this.items = this.navigationService.getNavPages();
  }

  toggleDrawer() {
    this.drawerExpanded = !this.drawerExpanded;

    this.subscription = this.isHandset$.subscribe(value => {
      if (Boolean(value)) {
        this.drawer.toggle();
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
