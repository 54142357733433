import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseApiService } from '@app/shared/services/abstract-api.service';

@Injectable()
export class ApiDisputeService extends BaseApiService {
  public getBaseUrl(): string {
    return this.configService.app.backend.dispute.url;
  }

  public appendData(params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): {} {
    return super.appendData(
      params,
      headers.set('Accept', `application/cashandcredit.dispute+json;version=${this.configService.app.backend.dispute.version}`)
    );
  }
}
