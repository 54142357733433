<main class="container" fxLayout="column">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button routerLink="/">
      <mat-icon svgIcon="finbox" class="logo"/>
    </button>
    <span fxFlex="auto"></span>
    <button mat-button
      *ngIf="(isAuthenticated$ | async)"
      i18n-matTooltip="@@logout" matTooltip="logout"
      (click)='logout()'
    >
      <mat-icon>logout</mat-icon>
      {{ authenticatedUserName }}
    </button>
  </mat-toolbar>
  <mat-sidenav-container fxFlex>
    <mat-sidenav #drawer
      (click)="drawerExpanded && toggleDrawer()"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
      <mat-nav-list>
        <a mat-list-item
           routerLinkActive="active-list-item"
           [matTooltip]="item.title" [matTooltipDisabled]="drawerExpanded" matTooltipClass="capitalize"
           [routerLink]="[item.path]"
           *ngFor="let item of items"
        >
          <div fxLayout="center">
            <mat-icon mat-list-icon>{{item.icon}}</mat-icon>
            <span mat-list-item class="capitalize" *ngIf="drawerExpanded">{{item.title}}</span>
          </div>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlex>
      <ng-content/>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-layout-skeleton-footer/>
</main>
