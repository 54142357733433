import { Title } from '@angular/platform-browser';
import { Component } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    private titleService: Title,
    private authService: AuthService,
  ) {
    this.titleService.setTitle($localize`:@@app.title:Cash & Credit Portal`);
    this.authService.runInitialLoginSequence();
  }
}
