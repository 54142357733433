import { Injectable } from '@angular/core';
import { UserInfo } from 'angular-oauth2-oidc';
import { AuthService } from '@app/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class VarsService {

    constructor(
        private authService: AuthService
    ) { }

    public user() {
        return this.authService.identityClaims as UserInfo;
    }
}
