import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';

export abstract class GenericDataSource<T> extends DataSource<T> {

  protected loadingSubject = new BehaviorSubject<boolean>(false);
  protected lengthSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public length$ = this.lengthSubject.asObservable();
}
