import { ActivatedRouteSnapshot, DetachedRouteHandle, BaseRouteReuseStrategy, Route } from '@angular/router';

export class CustomReuseStrategy extends BaseRouteReuseStrategy {
  private pool = new Map<string, DetachedRouteHandle>();

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.pool.get(this.getPath(route));
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.pool.get(this.getPath(route))!;
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return (
      route.routeConfig?.data?.shouldReuse && !this.pool.get(this.getPath(route))
    );
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null) {
    this.pool.set(this.getPath(route), handle!);
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig?.data?.shouldReuse
      ? this.getPath(future) === this.getPath(curr)
      : future.routeConfig === curr.routeConfig
      ;
  }

  public clear(route: ActivatedRouteSnapshot): void {
    const path = this.getPath(route);
    if (this.pool.has(path)) {
      (this.pool.get(path) as any).componentRef.destroy();
      this.pool.delete(path);
    }
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter(u => u.url.length)
      .map(u => u.url)
      .join('/')
      ;
  }
}
