export interface IAccount {
    ID_ACCOUNTS: number;
    ACCOUNTS_REF?: string;
    CONTACTS_GLOBAL_NAME?: string;
    CONTACTS_BUSINESS_COMPANY_NAME?: string;
    CONTACTS_BUSINESS_BRAND_NAME?: string;
    CONTACTS_BUSINESS_SIRET?: string;
    CONTACTS_INDIVIDUAL_FIRST_NAME?: string;
    CONTACTS_INDIVIDUAL_LAST_NAME?: string;
    CONTACTS_INDIVIDUAL_FULL_NAME?: string;
    DF_TITLES_ID_DF_TITLES?: string;
    DF_TITLES_SHORT_TITLE?: string;
    DF_TITLES_FULL_TITLE?: string;
    DF_FUNCTIONS_ID_DF_FUNCTIONS?: number;
    DF_FUNCTIONS_SHORT_LABEL?: string;
    ID_ENTITIES?: number;
    ENTITIES_CODE?: string;
    ENTITIES_NAME?: string;
    FI_ANA_OUTSTANDING?: number;
    FI_ANA_OVERDUE?: number;
    FI_ANA_DUE?: number;
    ACCOUNTS_FULL_NAME: string;
    CONTACTS_PHONE_NUMBER?: string;
    CONTACTS_EMAILS_EMAIL?: string;
}

export class Account {
    private state: IAccount;

    constructor(account: IAccount) {
        this.state = account;
    }

    get id() {
        return this.state.ID_ACCOUNTS;
    }

    get entity() {
        return this.state.ENTITIES_NAME;
    }

    get account() {
        return this.state.ACCOUNTS_REF;
    }

    get name() {
        return this.state.CONTACTS_BUSINESS_COMPANY_NAME;
    }

    get businessid() {
        return this.state.CONTACTS_BUSINESS_SIRET;
    }

    get fullName() {
        return this.state.ACCOUNTS_FULL_NAME;
    }
}
