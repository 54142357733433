import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuardWithForcedLogin } from '@app/auth/auth-guard-with-forced-login.service';
import { AuthGuard } from '@app/auth/auth-guard.service';
import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@app/shared/services/config.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthGuardWithForcedLogin,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useFactory: (config: ConfigService) => config.getAuthConfig(), deps: [ConfigService] },
        { provide: OAuthModuleConfig, useFactory: (config: ConfigService) => config.getAuthModuleConfig(), deps: [ConfigService] },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
