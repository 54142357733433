import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
  ) { }

  open(message: string, action = 'Success', duration = 3000) {
    this.zone.run(() => {
      const params = new MatSnackBarConfig();

      params.duration = duration;

      this.snackBar.open(message, action, params);
    });
  }
}
