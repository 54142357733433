import { Injectable } from '@angular/core';
import { BaseApiService } from '@app/shared/services/abstract-api.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class ApiV1Service extends BaseApiService {
    public getBaseUrl(): string {
        return this.configService.app.backend.statement.url;
    }

    public appendData(params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): {} {
        return super.appendData(
            params,
            headers.set('Accept', `version=${this.configService.app.backend.statement.version}`)
        );
    }
}
