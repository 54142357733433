import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VarsService } from '@app/shared/services/vars.service';
import {ApiParams, IApiGet, ApiGet, IApiGetRecord, ApiGetRecord} from '@app/shared/models/api.model';
import { ConfigService } from '@app/shared/services/config.service';

@Injectable()
export abstract class BaseApiService {

  constructor(
    public http: HttpClient,
    public varsService: VarsService,
    public configService: ConfigService
  ) { }

  public abstract getBaseUrl(): string;

  public appendData(params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): {} {
    return {
      params: params.set('user', this.varsService.user().preferred_username),
      headers: headers.set('Instance', `${this.configService.app.instance}`),
    };
  }

  public get(
    path: string,
    params: HttpParams = new HttpParams(),
    apiParams: ApiParams = new ApiParams(),
    headers: HttpHeaders = new HttpHeaders()): Observable<ApiGet> {
    return this.http.get(`${this.getBaseUrl()}${path}${apiParams}`, this.appendData(params, headers)).pipe(
      map((data: IApiGet) => new ApiGet(data))
    );
  }

  public getRecord(
    path: string,
    params: HttpParams = new HttpParams(),
    apiParams: ApiParams = new ApiParams(),
    headers: HttpHeaders = new HttpHeaders()): Observable<ApiGetRecord> {
    return this.http.get(`${this.getBaseUrl()}${path}${apiParams}`, this.appendData(params, headers)).pipe(
      map((data: IApiGetRecord) => new ApiGetRecord(data))
    );
  }

  public put(
    path: string,
    body: object = {},
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.put(
      `${this.getBaseUrl()}${path}`,
      JSON.stringify(body),
      this.appendData(params, headers)
    );
  }

  public post(
    path: string,
    body: object = {},
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.post(
      `${this.getBaseUrl()}${path}`,
      body,
      this.appendData(params, headers)
    );
  }

  public delete(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.delete(
      `${this.getBaseUrl()}${path}`,
      this.appendData(params, headers)
    );
  }
}
