import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ApiV1Service } from '@app/shared/services/api-v1.service';
import { Account } from '@app/account/models/account.model';
import { ApiGet, ApiParams } from '@app/shared/models/api.model';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AccountService } from '@app/account/services/account.service';

@Injectable()
export class RbacService implements Resolve<ApiGet>{

  private selection = new Map<number, Observable<ApiGet>>();

  constructor(
    private apiService: ApiV1Service,
    private accountService: AccountService,
  ) { }

  getPermissions(account: Account, params: HttpParams = new HttpParams(), apiParams: ApiParams = new ApiParams()): Observable<ApiGet> {
    if (!this.selection.has(account.id)) {
      this.selection.set(
        account.id,
        this.apiService.get(`/accounts/${account.id}/permissions`, params, apiParams)
          .pipe(
            map(response => {
              response.result = response.result.map((content: any) => content);
              return response;
            }),
            shareReplay(1),
          )
      );
    }

    return this.selection.get(account.id)!;
  }

  hasPrivilege() {
    return true;
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.accountService.getSelected(route.params.id).pipe(
      switchMap(account => this.getPermissions(account)),
    );
  }
}
