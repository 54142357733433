import { Injectable, Inject } from '@angular/core';
import { environment } from '@app/../environments/environment';
import { IConfig } from '@app/shared/models/config.model';
import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';

@Injectable()
export class ConfigService {
  constructor(
    @Inject('app') public app: IConfig
  ) { }

  getAuthConfig(): AuthConfig {
    return {
      issuer: this.app.auth.issuer,
      clientId: this.app.auth.clientId,
      responseType: 'code',
      redirectUri: window.location.origin,
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      scope: 'openid profile email offline_access',
      useSilentRefresh: true,
      sessionChecksEnabled: true,
      showDebugInformation: environment.production, // Also requires enabling "Verbose" level in devtools
      clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040
    }
  }

  getAuthModuleConfig(): OAuthModuleConfig {
    return {
      resourceServer: {
        allowedUrls: Object.values(this.app.backend).map(backend => backend.url),
        sendAccessToken: true,
      }
    }
  }
}
