import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MaterialModule } from '@app/shared/material.module';
import { SnackbarService } from '@app/shared/services/snackbar.service';
import { VarsService } from '@app/shared/services/vars.service';
import { ApiDisputeService } from '@app/shared/services/api-dispute.service';
import { ApiV1Service } from '@app/shared/services/api-v1.service';
import { RbacService } from '@app/shared/services/rbac.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResizeObserverDirective } from '@app/shared/directives/resize-observer.directive';
import { ResponsiveTableComponent } from '@app/shared/components/responsive-table/responsive-table.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    ResizeObserverDirective,
    ResponsiveTableComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  exports: [
    ResponsiveTableComponent,
    FlexLayoutModule,
  ],
  providers: [
    SnackbarService,
    VarsService,
    ApiDisputeService,
    ApiV1Service,
    RbacService,
  ],
})
export class SharedModule { }
