import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, Route, Router, Routes } from '@angular/router';

export interface NavigationPage {
  path: string;
  icon: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router: Router
  ) { }

  getNavPages(route?: ActivatedRoute): NavigationPage[] {
    let routes: Routes;

    if (route) {
      routes = route.routeConfig?.children as Routes;
    } else {
      routes = this.router.config;
    }

    return routes.filter(route => route.data && ['icon', 'title'].every(key => Object.keys(route.data as Data).includes(key)))
      .map(route => {
        const data = route.data as Data;

        return {
          path: String(route.path),
          icon: String(data.icon),
          title: String(data.title)
        };
      })
    ;
  }
}
