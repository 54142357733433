import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonComponent } from '@app/layout/skeleton/skeleton.component';
import { FooterComponent } from '@app/layout/skeleton/footer/footer.component';
import { MaterialModule } from '@app/shared/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MaterialModule,
  ],
  exports: [
    SkeletonComponent,
  ],
  declarations: [
    FooterComponent,
    SkeletonComponent,
  ]
})
export class LayoutModule { }
