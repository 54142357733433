import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthGuardWithForcedLogin } from '@app/auth/auth-guard-with-forced-login.service';
import { CustomReuseStrategy } from '@app/custom.routereusestrategy';

const routes: Routes = [
  {
    path: 'home',
    data: { icon: 'home', title: $localize`:@@home:home` },
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardWithForcedLogin],
  },
  {
    path: 'accounts',
    data: { icon: 'account_balance_wallet', title: $localize`:@@accounts:accounts` },
    loadChildren: () => import('@app/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardWithForcedLogin],
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
  ]
})
export class AppRoutingModule { }
